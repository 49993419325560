<template>
	<EventItemHead></EventItemHead>
	<div class="eventpage_wrap">
		<ul class="event_bnn_wrap" v-if="itemList.length > 0">
			<li class="event_bnn" v-for="(item, index) in itemList" :key="index" @click="goContentDetailPage(item)">
				<!-- @click="$router.push(`/detail/${item.opus_no == '' ? undefined : item.opus_no}`)" -->
				<a href="javascript:void(0);">
					<div class="event_img_wrap">
						<img alt="banner" :src="`${$image_host}/image/${item.thumb_path}`" />
					</div>
					<div class="event_txt_wrap">
						<dl>
							<dt>{{ item.evt_title }}</dt>
							<dd>
								<span>이벤트 기간</span> : {{ item.start_date.split(' ')[0] }} ~
								{{ item.end_date.split(' ')[0] }}
							</dd>
						</dl>
					</div>
				</a>
			</li>
		</ul>
		<div class="no_data" v-else>
			<span class="ic"></span>
			<span class="tit">Sorry</span>
			<p>데이터가 없습니다.</p>
		</div>
	</div>

	<Pagination v-if="itemList.length > 0" @changePage="changePage" :page="page" :totalPage="totalPage"></Pagination>
</template>

<script setup>
import EventItemHead from './EventItemHead.vue';
import Pagination from '@/shared-components/Pagination.vue';
import { ListByEventStore } from '@/store/contentStore';
import { storeToRefs } from 'pinia';
import goContentDetailPage from '@/common-methods/goContentDetailPage';

// store setting
const store = ListByEventStore();
store.getSubEventItems();
const { itemList, page, totalPage } = storeToRefs(store);

// change to state.page
const changePage = (page) => {
	store.setPage(page);
};
</script>

<style scoped>
@media screen and (min-width: 851px) {
	.eventpage_wrap {
		/* min-height: 975px; */
		min-height: 500px;
	}
}

@media screen and (min-width: 1201px) {
	.eventpage_wrap {
		min-height: 596px;
		/* min-height: 752px; */
	}
}

ul.event_bnn_wrap li.event_bnn {
	width: 49%;
	max-width: 585px;
	padding-bottom: 20px;
}

@media (max-width: 660px) {
	ul.event_bnn_wrap li.event_bnn {
		width: 100%;
		max-width: 100%;
	}
}
</style>

import { userInfoStore } from '@/store/UserStore';
import { storeToRefs } from 'pinia';
import router from '@/router';
import moment from 'moment';

const userStore = userInfoStore();

const { user_info_list } = storeToRefs(userStore);

export default (content) => {
	console.log(content);
	if (content.age_limit == 19) {
		try {
			// user_info_list가 null 일 경우 로그인이 풀린 상태
			if (!user_info_list.value.birth_date) {
				alert('본인인증을 먼저 진행해주세요1');
				return false;
			} else {
				const birth_date = moment(user_info_list.value.birth_date);
				if (moment().diff(birth_date.format('YYYYMMDD'), 'years') >= 19) {
					router.push(`/detail/${content.opus_no}`);
				} else {
					alert('성인 작품입니다!');
					return false;
				}
			}
		} catch {
			alert('성인용 작품은 로그인이 필요합니다');
			return false;
		}
		console.log('19금 작품');
	} else {
		router.push(`/detail/${content.opus_no}`);
		console.log('전체연령 작품');
	}
};

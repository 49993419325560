import { defineStore } from 'pinia';
import ajax from '@/api/api-manager';
import moment from 'moment';
import log_response from '@/common-methods/AxiosResponseLog';

// 메인 홈페이지 최상단 메인 배너 리스트
export const HomeMainBannerStore = defineStore('HomeMainBanner', {
	state: () => ({
		bannerList: [],
		activeIndex: 0,
		totalIndex: 1,
	}),
	getters: {},
	actions: {
		getHomeMainBanner() {
			ajax('home/banner', null).then((response) => {
				this.bannerList = response.data.data;
				this.totalIndex = response.data.data.length;
				log_response('getHomeMainBanner', response.data.data);
			});
		},
	},
});

// 메인 홈페이지 신작 소개 배너 리스트
export const HomeNewBannerStore = defineStore('HomeNewMBanner', {
	state: () => ({
		bannerList: [],
		activeIndex: 0,
		totalIndex: 1,
	}),
	getters: {},
	actions: {
		// [메인] 신작 작품 리스트 전용
		getHomeNewBanner() {
			ajax('home/byNewBanner', null).then((response) => {
				this.bannerList = response.data.data;
				this.totalIndex = response.data.data.length;
				log_response('getHomeNewBanner', response.data.data);
			});
		},
	},
});

export const HomeRealTimeRankingStore = defineStore('HomeRealTimeRanking', {
	state: () => ({
		itemList: [],
		date: moment(),
	}),
	getters: {},
	actions: {
		// [메인] 실시간 랭킹 작품 리스트 전용
		getHomeRealTimeRanking() {
			ajax('home/byRanking', null).then((response) => {
				this.itemList = response.data.data;
				this.date = moment();
				log_response('getHomeRealTimeRanking', response.data.data);
			});
		},

		// 랭킹 시간 새로고침
		// refreshCurrentTime() {
		// 	this.getHomeRealTimeRanking();
		// 	// this.date = moment();
		// },
	},
});

export const HomeEventBannerStore = defineStore('HomeEventBanner', {
	state: () => ({
		bannerList: [],
		activeIndex: 0,
		totalIndex: 1,
	}),
	getters: {},
	actions: {
		// [메인] 신작 작품 리스트 전용
		getHomeEventBanner() {
			ajax('home/byEventBanner', null).then((response) => {
				this.bannerList = response.data.data;
				this.totalIndex = response.data.data.length;
				log_response('getHomeEventBanner', response.data.data);
			});
		},
	},
});

// 장르별 작품 리스트 관련 모음
export const ListByGenreStore = defineStore('ListByGenre', {
	state: () => ({
		//스토어 내에 기본값 생성
		itemList: [],
		genre: 'ALL',
		page: 1,
		perPage: null,
		totalPage: null,
		totalItemCount: null,
	}),
	getters: {},

	actions: {
		// [메인] 장르별 작품 리스트 전용
		getHomeGenreItems() {
			const params = {
				genre: this.genre,
			};
			ajax('home/byGenre', params).then((response) => {
				this.itemList = response.data.data;
				log_response('getHomeGenreItems', response.data.data);
			});
		},
		// [서브] 장르별 작품 리스트 페이지 전용
		getSubGenreItems() {
			const params = {
				genre: this.genre,
				page: this.page,
			};
			ajax('sub/byGenre', params).then((response) => {
				this.itemList = response.data.data;
				this.perPage = response.data.perpage;
				this.totalPage = response.data.totalpage;
				this.totalItemCount = response.data.totalrecordcount;
				log_response('getSubGenreItems', response.data);
			});
		},
		// 장르탭에서 장르 변경 시 실행됨
		setGenre(genre) {
			this.page = 1;
			this.genre = genre;
		},
		// 페이지 변경 시
		setPage(page) {
			this.page = page;
		},
	},
});

// 신작 작품 리스트 관련 모음
export const ListByNewStore = defineStore('ListByNew', {
	state: () => ({
		itemList: [],
		genre: 'ALL',
		page: 1,
		perPage: null,
		totalPage: null,
		totalItemCount: null,
	}),
	getters: {},
	actions: {
		// [서브] 신작 작품 리스트 페이지 전용
		getSubNewItems() {
			const params = {
				genre: this.genre,
				page: this.page,
			};
			ajax('sub/byNew', params).then((response) => {
				this.itemList = response.data.data;
				this.perPage = response.data.perpage;
				this.totalPage = response.data.totalpage;
				this.totalItemCount = response.data.totalrecordcount;
				log_response('getSubNewItems', response.data);
			});
		},
		// 장르탭에서 장르 변경 시 실행됨
		setGenre(genre) {
			this.page = 1;
			this.genre = genre;
		},
		// 페이지 변경 시
		setPage(page) {
			this.page = page;
		},
	},
});

// 작품 랭킹 리스트 관련 모음
export const ListByRankingStore = defineStore('ListByRanking', {
	state: () => ({
		itemList: [],
		genre: 'ALL',
		page: 1,
		perPage: null,
		totalPage: null,
		totalItemCount: null,
		order: null, // 실시간, 일간, 월간
		date: moment(),
	}),
	getters: {},
	actions: {
		// [서브] 신작 작품 리스트 페이지 전용
		getSubRankingItems() {
			const params = {
				genre: this.genre,
				page: this.page,
				type: this.order,
			};
			ajax('sub/byRanking', params).then((response) => {
				this.itemList = response.data.data;
				this.perPage = response.data.perpage;
				this.totalPage = response.data.totalpage;
				this.totalItemCount = response.data.totalrecordcount;
				this.date = moment();
				log_response('getSubRankingItems', response.data);
			});
		},
		// 장르탭에서 장르 변경 시 실행됨
		setGenre(genre) {
			this.page = 1;
			this.genre = genre;
		},

		// 정렬 타입 변경 시 실행됨
		setOrder(order) {
			this.page = 1;
			this.order = order;
		},

		// 페이지 변경 시
		setPage(page) {
			this.page = page;
		},
	},
});

// 신작 작품 리스트 관련 모음
export const ListByEventStore = defineStore('ListByEvent', {
	state: () => ({
		itemList: [],
		state: 'P',
		page: 1,
		perPage: 4,
		totalPage: null,
		totalItemCount: null,
	}),
	getters: {},
	actions: {
		// [서브] 이벤트 리스트 페이지 전용
		getSubEventItems() {
			const params = {
				state: this.state,
				page: this.page,
				perPage: this.perPage,
			};
			ajax('sub/byEvent', params).then((response) => {
				this.itemList = response.data.data;
				this.perPage = response.data.perpage;
				this.totalPage = response.data.totalpage;
				this.totalItemCount = response.data.totalrecordcount;
				log_response('getSubEventItems', response.data);
			});
		},
		// 이벤트 헤더에서 진행중 OR 종료된 이벤트 선택 시 호출
		setState(state) {
			this.page = 1;
			this.state = state;
			this.getSubEventItems();
		},

		// 페이지 변경 시 호출
		setPage(page) {
			this.page = page;
			this.getSubEventItems();
		},
	},
});

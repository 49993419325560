<template>
	<div class="tit_wrap">
		<h2>이벤트</h2>
		<!-- <button><img alt="" src="@/assets/images/icon_go_arrow_line.png" /></button> -->
	</div>
	<div class="tab_wrap">
		<ul>
			<li @click="store.setState('P')" :class="{ active: state === 'P' }">
				<a href="javascript:void(0);">진행중인 이벤트</a>
			</li>
			<li @click="store.setState('C')" :class="{ active: state === 'C' }">
				<a href="javascript:void(0);">종료된 이벤트</a>
			</li>
		</ul>
	</div>
</template>

<script setup>
import { ListByEventStore } from '@/store/contentStore';
import { storeToRefs } from 'pinia';

// store setting
const store = ListByEventStore();
const { state } = storeToRefs(store);
</script>

<style></style>
